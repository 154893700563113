import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-header" }
const _hoisted_2 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Arvyo")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/services" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Services")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/portfolio" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Portfolio")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/contact" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Contact")
        ])),
        _: 1
      })
    ])
  ]))
}