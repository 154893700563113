
import { defineComponent } from 'vue';
import HeaderComponent from '@/components/Header.component.vue';

export default defineComponent({
  name: 'PublicLayout',
  components: {
    HeaderComponent,
  },
});
