
import { defineComponent } from 'vue';
import PublicLayout from '@/views/layouts/Public.Layout.vue';

export default defineComponent({
  name: 'ArvyoApp',
  components: {
    PublicLayout,
  },
});
